import React from 'react';
import verksamhetspolicy from '../images/pdf/verksamhetspolicy.pdf';
import Helmet from 'react-helmet';
import favicon from '../images/favicon.ico';

function Policy() {
    return (
        <>
            <Helmet>
                <title>Policy - Danas Plåt &amp; Smide AB</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="plåtslageri och smidesverkstad som tar uppdrag i Södertälje, Stockholm, Mälarområdet, Södermanland och Uppland." />
                <meta name="keywords" content="plåt, smide, Blästring, Rostskyddsmålning, Termisk Sprutning" />
                <meta property="og:title" content="Kontakt - Danas Plåt och Smide AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://danasplat.se/policy" />
                <link rel="cannonical" href="https://danasplat.se/policy" />
            </Helmet>
            <iframe 
                title="Verksamhetspolicy Danas Plåt och Smide AB"
                src={verksamhetspolicy}
                style={{height: '100vh',
                        width: '100vw',
            }}>
            <p>This browser does not support PDFs. Please download the PDF to view it: Download PDF </p>
            </iframe>
        </>
    );
}
export default Policy;